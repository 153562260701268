<template>
    <div>
        <v-divider v-if="topLine" :style="dark ? 'border-color: rgba(255, 255, 255, .2)' : ''" />
        <div class="pa-20px pa-md-30px px-lg-80px py-lg-60px">
            <v-row no-gutters>
                <v-col data-aos="fade-up" cols="12" md="6" class="mb-12px mb-md-0">
                    <tit :class="dark ? 'white--text' : ''">{{title}}</tit>
                </v-col>
                <v-col data-aos="fade-up" data-aos-delay="100" cols="12" md="6">
                    <!-- <txt :class="dark ? 'white--text' : 'txt--dark'">
                        <slot />
                    </txt> -->
                    <slot />
                </v-col>
            </v-row>
        </div>
        <v-divider :style="dark ? 'border-color: rgba(255, 255, 255, .2)' : ''" />
    </div>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    props: {
        title: { type: String, default: "" },
        dark: { type: Boolean, default: false },
        topLine: { type: Boolean, default: false },
    },
    components: {
        Tit,
        Txt,
    },
}
</script>

<style>

</style>