<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="업무분야" tabActive="재개발·재건축" bg="/images/sub/visual/sv-business.jpg" />
        </template>
        <page-section tit="재개발·재건축" titAdd="Business" class="text-bg" style="background-image: url(/images/sub/business/business-bg.svg)">
            <div class="card-rows">
                <card-row v-for="(cardRow, index) in cardRows" :key="index" :image="cardRow.image">
                    <v-row>
                        <v-col v-if="cardRow.title" cols="12">
                            <tit>
                                <div v-html="cardRow.title" />
                            </tit>
                        </v-col>
                        <v-col cols="12">
                            <p v-html="cardRow.text" />
                        </v-col>
                    </v-row>
                </card-row>
            </div>
        </page-section>
        <page-section tit="주요 업무" titAdd="Business" dark style="background-image: url(/images/sub/business/maintenance/maintenance-bg.jpg)">
            <info-row v-for="(item, index) in info" :key="index" :title="item.title" :topLine="index == 0" dark>
                <!-- <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{ detail }}
                </info-detail> -->
                <v-row>
                    <v-col v-for="(detail, index) in item.infoDetail" :key="index" cols="12">
                        <tit class="tit--sm white--text mb-12px mb-md-16px">{{ detail.title }}</tit>
                        <txt class="white--text">
                            <v-row class="row--sm">
                                <v-col v-for="(child, index) in detail.children" :key="index" cols="12">
                                    <v-row no-gutters class="row--sm">
                                        <v-col cols="auto">·&nbsp;</v-col>
                                        <v-col>{{ child }}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </txt>
                    </v-col>
                </v-row>
            </info-row>
        </page-section>
        <page-section tit="주요 업무사례" titAdd="Business" titLeft class="text-bg--2 pb-0" style="background-image: url(/images/sub/business/business-bg2.svg)"> </page-section>
        <page-section class="page-section--last page-section--xs grey lighten-5">
            <v-row>
                <v-col v-for="(detail, index) in precedent" :key="index" cols="12" md="6">
                    <card-primary data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? index * 50 : 0">
                        <txt class="txt--dark">{{ detail }}</txt>
                    </card-primary>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        InfoRow,
        InfoDetail,
        CardPrimary,

        Tit,
        Txt,
    },
    data() {
        return {
            cardRows: [
                {
                    title: "ALWAYS AHEAD, <br class='d-none d-sm-block' />COMPREHENSIVE SOLUTION",
                    text: "태성의 정비사업팀이 추구하는 목표는 단순합니다. <br class='d-none d-sm-block' />조합의 성공을 위해 종합적인 해법을 한발 앞서 제시하는 것입니다.",
                    image: "/images/sub/business/renovation/renovation-img.jpg",
                },
                {
                    title: "PERFORMANCE, <br class='d-none d-sm-block' />최고를 향한 도전",
                    text: "태성은 항상 혁신합니다. <br class='d-none d-sm-block' />끊임없는 혁신으로 정비사업 조합에게 더 나은 서비스를 제공하겠습니다.",
                    image: "/images/sub/business/renovation/renovation-img2.jpg",
                },
                {
                    title: "정비사업의 성공과 가치 실현을 위한, <br class='d-none d-sm-block d-lg-none d-xl-block' />굿 파트너",
                    text: "태성은 정비사업의 가치 실현을 위해, <br class='d-none d-sm-block' />조합의 동반자로서 정비사업의 성공을 향해 진심을 다합니다.",
                    image: "/images/sub/business/renovation/renovation-img3.jpg",
                },
            ],
            info: [
                {
                    title: "정비사업 법률자문 관련",
                    infoDetail: [
                        {
                            title: "자문 및 소송",
                            children: [
                                "정비사업조합의 설립, 운영자문",
                                "정비사업에 관한 행정절차(인허가) 자문 및 소송",
                                "협력업체(시공사등) 선정 및 해지 자문 및 소송",
                                "공사비·용역비 대응 자문 및 소송",
                                "조합 내부 분쟁자문 및 소송",
                                "건설 관련 분쟁(일조권 등) 대응 자문",
                                "조합 임원 형사사건",
                                "매도·명도소송, 수용·재결",
                                //
                            ],
                        },
                        {
                            title: "등기",
                            children: [
                                "조합 법인 등기",
                                "정비사업에 관한 모든 등기",
                                //
                            ],
                        },
                        {
                            title: "용역",
                            children: [
                                "공사비적정성검토",
                                "시공상 하자 검토 및 소송",
                                "정비사업 사업성 개선",
                                //
                            ],
                        },
                        //
                    ],
                },
            ],
            precedent: [
                "응암3주택재건축정비사업조합",
                "남산 4-4지구 주택재개발정비사업조합",
                "전농제7구역 주택재개발정비사업조합",
                "삼호동호진주 가로주택정비사업조합",
                "백운연립2단지 주택재건축 정비사업조합",
                "태평1구역 주택재개발정비사업조합",
                "신정2재정비촉진구역1지구 주택재개발정비사업조합",
                "온천2구역 주택재개발정비사업조합",
                "율량사천구역 주택재건축 정비사업조합",
                "흑석3재정비촉진구역 주택재개발정비사업조합",
                "성남중1 주택재개발정비사업조합",
                "염주주공아파트 주택재개발정비사업조합",
                "111구역정자지구 주택개재개발정비사업조합",
                "광명 제15R구역 주택재개발정비사업조합",
                "선화재정비촉진구역 주택재개발정비사업조합",
                "상록주택재개발정비사업조합",
                "삼익2차아파트 리모델링주택조합",
                "원일빌라주택재건축정비사업조합",
                //
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
