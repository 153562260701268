<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="Lawfirm taesung & P.E" tabActive="변호사 소개" bg="/images/sub/visual/sv-introduction.jpg" />
        </template>

        <introduction-yoon />
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import IntroductionYoon from "@/components/client/sub/introduction/introduction-yoon.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        IntroductionYoon,
    },
};
</script>

<style lang="scss" scoped>
</style>
