import { render, staticRenderFns } from "./IntroductionLawyerListPage.vue?vue&type=template&id=247bb06e&scoped=true&"
import script from "./IntroductionLawyerListPage.vue?vue&type=script&lang=js&"
export * from "./IntroductionLawyerListPage.vue?vue&type=script&lang=js&"
import style0 from "./IntroductionLawyerListPage.vue?vue&type=style&index=0&id=247bb06e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247bb06e",
  null
  
)

export default component.exports