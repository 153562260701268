var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "업무분야",
            "tabActive": "재개발·재건축",
            "bg": "/images/sub/visual/sv-business.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "text-bg",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg.svg)"
    },
    attrs: {
      "tit": "재개발·재건축",
      "titAdd": "Business"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, _vm._l(_vm.cardRows, function (cardRow, index) {
    return _c('card-row', {
      key: index,
      attrs: {
        "image": cardRow.image
      }
    }, [_c('v-row', [cardRow.title ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('tit', [_c('div', {
      domProps: {
        "innerHTML": _vm._s(cardRow.title)
      }
    })])], 1) : _vm._e(), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(cardRow.text)
      }
    })])], 1)], 1);
  }), 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/business/maintenance/maintenance-bg.jpg)"
    },
    attrs: {
      "tit": "주요 업무",
      "titAdd": "Business",
      "dark": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": item.title,
        "topLine": index == 0,
        "dark": ""
      }
    }, [_c('v-row', _vm._l(item.infoDetail, function (detail, index) {
      return _c('v-col', {
        key: index,
        attrs: {
          "cols": "12"
        }
      }, [_c('tit', {
        staticClass: "tit--sm white--text mb-12px mb-md-16px"
      }, [_vm._v(_vm._s(detail.title))]), _c('txt', {
        staticClass: "white--text"
      }, [_c('v-row', {
        staticClass: "row--sm"
      }, _vm._l(detail.children, function (child, index) {
        return _c('v-col', {
          key: index,
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("· ")]), _c('v-col', [_vm._v(_vm._s(child))])], 1)], 1);
      }), 1)], 1)], 1);
    }), 1)], 1);
  }), 1), _c('page-section', {
    staticClass: "text-bg--2 pb-0",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg2.svg)"
    },
    attrs: {
      "tit": "주요 업무사례",
      "titAdd": "Business",
      "titLeft": ""
    }
  }), _c('page-section', {
    staticClass: "page-section--last page-section--xs grey lighten-5"
  }, [_c('v-row', _vm._l(_vm.precedent, function (detail, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('card-primary', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.mdAndUp ? index * 50 : 0
      }
    }, [_c('txt', {
      staticClass: "txt--dark"
    }, [_vm._v(_vm._s(detail))])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }