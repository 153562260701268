<template>
    <div class="card-row-wrap">
        <v-row align="center" class="row--xl ma-xl-n50px card-row">
            <v-col data-aos="fade-left" cols="12" lg="6" class="pa-xl-50px">
                <v-responsive :aspect-ratio="670 / 500" class="card-row__image-wrap">
                    <v-img :src="image" :aspect-ratio="870 / 500" height="100%" class="card-row__image w-100"/>
                </v-responsive>                
            </v-col>
            <v-col data-aos="fade-right" data-aos-delay="100" cols="12" lg="6" class="pa-xl-50px">
                <txt class="txt--xl txt--dark line-height-17">
                    <slot />
                </txt>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {
        image: { type: String, default: "" },
        title: { type: String, default: "" },
        txt: { type: String, default: "" },
        caption: { type: String, default: "" },
    },
    components: {
        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>
.card-row{
    &__image{
        min-width: calc(100% + 12px);
        position: absolute;
        top: 0;
        right: 0;
        &-wrap{
            overflow: visible;
            position: relative;            
        }
    }
}
.card-rows>.card-row-wrap:nth-child(2n -1) .card-row{
    .card-row__image{
        right: unset;
        left: 0;
    }
}
.card-rows>.card-row-wrap:not(:last-child){
    margin-bottom: 50px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .card-rows>.card-row-wrap:nth-child(2n - 1) .card-row{
        flex-direction: row-reverse;
    }
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 70px;
    }
}
@media (min-width:1024px){
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 80px;
    }
}
@media (min-width:1200px){
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 70px;
    }
}
@media (min-width:1544px){
    .card-row{
        &__image{
            min-width: calc((100vw - 180px) / 2);
        }
    }
}

</style>