var render = function render(){
  var _this$lawyer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Lawfirm taesung & P.E",
            "tabActive": "변호사 소개",
            "bg": "/images/sub/visual/sv-introduction.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "lawyer-view__head",
    attrs: {
      "tit": "변호사 소개",
      "titAdd": "Introduction",
      "titClass": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "pageSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "text-bg w-100",
          attrs: {
            "src": "/images/sub/introduction/lawyer/lawyer-bg.svg",
            "max-width": "1840",
            "aspect-ratio": 1840 / 266
          }
        })];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "lawyer-view__body page-section--sm grey darken-3"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7"
    }
  }, [_c('div', {
    staticClass: "lawyer-view__body-contents"
  }, [_c('tit', {
    staticClass: "lawyer-view__body-tit tit--lg white--text",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [this.lawyer.comment ? [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_this$lawyer = this.lawyer) === null || _this$lawyer === void 0 ? void 0 : _this$lawyer.comment)
    }
  })] : [_vm._v(" 법무법인 태성의 변호사는 "), _c('br'), _vm._v(" 언제나 여러분 곁에 함께 하겠습니다. ")]], 2), _c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit', {
    staticClass: "tit--sm primary--text line-height-1 font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(_vm._s(this.lawyer.titleEn))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tit', {
    staticClass: "tit--xxl white--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(_vm._s(this.lawyer.title))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xl white--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [this.lawyer.ceo ? _c('span', [_vm._v("대표 ")]) : _vm._e(), _vm._v("변호사")])], 1)], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "8",
      "sm": "5",
      "offset": "4",
      "offset-sm": "7",
      "offset-md": "0"
    }
  }, [_c('div', {
    staticClass: "lawyer-img-wrap"
  }, [_c('v-img', {
    staticClass: "lawyer-img w-100",
    attrs: {
      "data-aos": "fade-left",
      "src": '/images/lawyer/view/' + this.lawyer.title + '.png',
      "max-width": "586",
      "aspect-ratio": 586 / 900
    }
  })], 1)])], 1)], 1), this.lawyer.info ? _c('page-section', {
    staticClass: "lawyer-view__foot page-section--last page-section--xs"
  }, _vm._l(this.lawyer.info, function (info, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": info.title,
        "topLine": index == 0
      }
    }, _vm._l(info.infoDetail, function (detail, index) {
      return _c('info-detail', {
        key: index,
        class: index !== 0 ? 'mt-10px mt-md-16px' : ''
      }, [typeof detail === 'string' ? _c('span', [_vm._v(_vm._s(detail))]) : detail.bold ? _c('strong', [_vm._v(_vm._s(detail.txt))]) : _vm._e()]);
    }), 1);
  }), 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }